$big-logo-height: 122px;
$small-logo-height: 82px;
$big-header-height: 229px;

.site-header {
  background-color: #0c110c;
  .wrapper {
    background: url('/assets/header.jpg');
    background-position: top right;
    background-repeat: no-repeat;
    background-size: auto 100%;
    @media screen and (min-width: $on-large) {
      height: $big-header-height;
      background-position: top left;
      position: relative;
    }
  }
}
.logo {
  path {
    fill: white;
  }
  svg {
    height: $small-logo-height;
  }
  margin: 5px 0;
  line-height: 0;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, .7));

  @media screen and (min-width: $on-large) {
    margin-top: 34px;
    margin-bottom: 34px;

    svg {
      height: $big-logo-height;
    }
  }
}
main {
  img, .iframe-container {
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, .5));
  }

  h2 {
    margin-top: $spacing-unit;
    color: $brand-color;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans Condensed', sans-serif;
    font-weight: 700;
  }
}

table.unstyled {
  color: $text-color;
  border-collapse: collapse;
  border: none;
  tr {
    background-color: inherit;
  }
  th, td {
    padding: 0 $spacing-unit * 0.5 $spacing-unit * 0.125 0;
    vertical-align: top;
    background-color: inherit;
    border: none;
  }
  margin-bottom: $spacing-unit * (3*0.125);
}

.next-event {
  color: $background-color;
  background-color: $brand-color;
  font-size: $base-font-size * 1.5;
  margin-bottom: $spacing-unit;
  padding: ($spacing-unit * 0.25) ($spacing-unit * 0.5);

  a {
    color: inherit;
  }
}

@media screen and (min-width: $on-large) {
  img.float-left {
    float: left;
    margin: $spacing-unit * 0.5;
    margin-left: 0;
  }
  img.float-right {
    float: right;
    margin: $spacing-unit * 0.5;
    margin-right: 0;
  }
}
img.full-width {
  width: 100%;
}

.lyrics {
  h1 span, span.line {
    display: block;
  }

  rt {
    font-size: 67%;
  }

  h1 rt {
    font-size: 33%;
  }
}

.show-jpan, .show-jpan-hira {
  .lyrics span[lang="ja-Latn"] {
    display: none;
  }
}
.show-jpan {
  .lyrics rt {
    display: none;
  }
}
.show-latn {
  .lyrics span[lang="ja-Jpan"] {
    display: none;
  }
}

.show-jpan-hira, .show-all {
  .lyrics p {
    line-height: $base-line-height * 1.5;
  }
}

@media screen and (min-width: $on-large) {
  .show-all .lyrics {
    p {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      span.line {
        width: 49%;
      }
    }
  }
}

form#lang-switcher {
  margin-bottom: $spacing-unit;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  input[type="radio"] {
    display: none;
    & + label {
      cursor: pointer;
      border: 2px solid $text-color;
      padding: ($spacing-unit * 0.25) ($spacing-unit * 0.5);
      margin-right: -2px;
      margin-bottom: -2px;
    }

    &:checked + label {
      background-color: $text-color;
      color: $background-color;
    }
  }
}

.link-list {
  margin-left: 0;
  list-style: none;
  font-size: $base-font-size * 1.5;
}

ul.sponsor-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  list-style: none;

  li {
    max-width: 200px;
    margin-right: $spacing-unit;
    margin-bottom: $spacing-unit * 0.5;
  }

  img {
    height: 42px;
    filter: none;
  }
}

.social-media-list li a {
  border: none;
  padding: 0;
  .svg-icon {
    padding-top: 0;
  }
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 15px;

  iframe {
     border: 0;
     height: 100%;
     left: 0;
     position: absolute;
     top: 0;
     width: 100%;
  }
}

.iframe-container-soundcloud {
  padding-top: 0;
  height: 166px;
}

.iframe-container-4x3 {
  padding-top: 75%;
}

.iframe-container-16x9 {
  padding-top: 56.25%;
}

video {
  width: 100%;
  margin-bottom: 15px;
}
